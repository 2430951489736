<template>
  <div v-highlight>
    <pre class="language-javascript">
      <code v-html="codeWithTranslations"></code>
    </pre>
  </div>
</template>

<script>
import VueCodeHighlight from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import i18n from "@/plugins/i18n";

export default {
  components: {
    VueCodeHighlight,
  },
  computed: {
    codeWithTranslations() {
      const currentLocale = this.$i18n.locale;
      
      const translations = {
        en: {
          successComment: 'in case of successful operation you get true, in case of error false',
          paymentIdComment: 'payment id (in case of successful operation)',
          dataComment: 'payment object in json format encoded in base64_encode (in case of successful operation)',
          signComment: 'response signature (in case of successful operation)',
          errorCodeComment: 'error code in case of unsuccessful operation',
          errorMsgComment: 'error message in case of unsuccessful operation'
        },
        ru: {
          successComment: 'в случае успешной операции получаете true, в случае ошибки false',
          paymentIdComment: 'id платежа (в случае успешной операции)',
          dataComment: 'объект платежа в формате json закодированный в base64_encode (в случае успешной операции)',
          signComment: 'подпись ответа (в случае успешной операции)',
          errorCodeComment: 'код ошибки при не успешной операции',
          errorMsgComment: 'сообщение ошибки при не успешной операции'
        }
      };

      const t = translations[currentLocale];

      return `
&lt;?php

    $response = '{
      "data": "eyJwYXltZW50X2lkIjo4MDM3NDE0OTQzMTc1NTgwOSwib3JkZXJfaWQiOiJERVZfUGF5aW5fdjJfcHBfMV9zdGFnZTE3MzYwNzYzMjYxMTgiLCJwYXltZW50X3R5cGUiOiJwYXkiLCJwYXltZW50X21ldGhvZCI6ImVjb20iLCJwYXltZW50X3N0YXR1cyI6ImNyZWF0ZWQiLCJhbW91bnQiOjEsImFtb3VudF9pbml0aWFsIjoxLCJjdXJyZW5jeSI6IktaVCIsImNhcHR1cmVkX2RldGFpbHMiOnt9LCJjYW5jZWxfZGV0YWlscyI6e30sImNyZWF0ZWRfZGF0ZSI6IjIwMjUtMDEtMDUgMTE6MjU6MjYgKzAwMDAiLCJwYXllcl9pbmZvIjp7ImVtYWlsIjoiYWJvYmFAb3Z0ZWNoLmt6In0sInBheW1lbnRfcGFnZV91cmwiOiJodHRwczovL29uZXZpc2lvbi1kZXYuZnJvbnQtb2ZmaWNlLXBheW1lbnQtcGFnZS1hcHAub25ldmlzaW9ucGF5LmNvbS9wcC9wYXltZW50X3BhZ2UvYTk4YzY0ODktMjgyOS00MzY2LWE5MjQtMDI1YWJjN2EyMzM0P2xhbmc9cnUiLCJ3YWxsZXRfaWRlbnRpZmllciI6e319",
      "payment_id": "80374149431755809",
      "sign": "3f4259ecc5d91662f1d439f825a5adeba45f504b82c8d616f6cd15ca8ece4ee25872b6909493ac65b52dc6098f6ee7ea77eabf412cc9c1a8a082867f9f381a44",
      "success": true
    }';

    $responseObject = json_decode($response, true);

    $data = $responseObject['data'] ?? null;
    $payment_id = $responseObject['payment_id'] ?? null;
    $sign = $responseObject['sign'] ?? null;
    $success = $responseObject['success'] ?? false;

    if ($success) {
      echo "Payment was successful.";
      echo "Payment ID: $payment_id";
      echo "Data: $data";
      echo "Sign: $sign";
    } else {
      echo "Payment failed.";
    }

?&gt;
      `;
    }
  }
};
</script>
