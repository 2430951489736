<template>
  <div class="api-block test-mode">
    <div>
      <h2 class="api-h1">{{ $t('test_env') }}</h2>
    </div>
    <p class="api-description">
      {{ $t('test_env_sandbox') }}
    </p>
    <p class="api-description">
      <i18n path="before_using_test_env">
        <b place="number">4111 1111 1111 1111</b>
      </i18n>
    </p>
    <p class="api-description">
      {{ $t('test_env_func') }}
    </p>
    <div class="api-h3 bt-grey">1. {{ $t('payment_page') }}.</div>
    <p class="api-description">
      {{ $t('req_payment_page') }}
    </p>
    <ul class="api-list">
      <li>Pan: <b>4111 1111 1111 1111</b></li>
      <li>Exp date: {{ $t('valid') }}.</li>
      <li>
        CVV: <b>111</b>({{ $t('success') }}), <b>222</b>(ov_server_error),
        <b>333</b>(ov_card_incorrect_data), <b>444</b> (provider_common_error)
      </li>
    </ul>
    <div class="api-h3 bt-grey">2. {{ $t('host_host_reception') }}.</div>
    <p class="api-description">
      <span>
        При запросе <b>payment/direct</b> в тело запроса необходимо добавить test_mode: <b>1 </b>(успех), <br> <b>2</b> (ov_server_error), <br> <b>3</b> (ov_card_incorrect_data), <br> <b>4</b> (provider_common_error), <br> <b>5</b> (Для теста 3DS проверки, в ответе на запрос вернется URL для редиректа. На странице полученной в ответе необходимо ввести ОТП код «1111» для получения успеха. Затем нужно будет отправить запрос на payment/confirm, раздел «Подтверждение оплаты»).
      </span>
    </p>
    <div class="api-h3 bt-grey">3. {{$t('host_payments')}}</div>
    <p class="api-description">
      
      <i18n path="host_payments_desc">
        <b place="direct">payment/direct</b>
        <b place="1">1</b>
        <b place="2">2</b>
        <b place="pan">PAN</b>
      </i18n>
    </p>
    <div class="api-h3 bt-grey">4. {{ $t('refund_req') }}.</div>
    <p class="api-description">
      <i18n path="refund_req_desc">
        <b place="refund">payment/refund</b>
        <b place="1">1</b>
        <b place="2">2</b>
      </i18n>
    </p>
    <div class="api-h3 bt-grey">5. {{ $t('req_status') }}.</div>
    <p class="api-description">
      <i18n path="req_status_desc">
        <b place="status">payment/status</b>
      </i18n>
    </p>
    <div class="api-h3 bt-grey">6. {{ $t('check_balance') }}.</div>
    <p class="api-description">
      <i18n path="check_balance_desc">
        <b place="balance">payment/payout_balance</b>
      </i18n>
    </p>
    <div class="api-h3 bt-grey">7. {{ $t('recurrent_payments') }}.</div>
    <p class="api-description">
      <i18n path="recurrent_payments_desc">
        <b place="recurrent">payment/recurrent</b>
        <b place="1">1</b>
        <b place="2">2</b>
        <b place="3">3</b>
        <b place="4">4</b>
      </i18n>
    </p>
    <div class="api-h3 bt-grey">8. Отмена платежа</div>
    <p class="api-description">
      <span>
        При запросе <b>payment/cancel</b> в тело запроса необходимо добавить test_mode: <b>1 </b>(успех), <b>2</b> (неуспех).
      </span>
    </p>
    <div class="api-h3 bt-grey">9. Запрос на клиринг</div>
    <p class="api-description">
      <span>
        При запросе <b>payment/capture</b> в тело запроса необходимо добавить test_mode: <b>1 </b>(успех), <b>2</b> (неуспех).
      </span>
    </p>
    <div class="api-h3 bt-grey">10. Подтверждение оплаты</div>
    <p class="api-description">
      <span>
        При запросе <b>payment/confirm</b> в тело запроса необходимо добавить test_mode: <b>1 </b>(успех), <b>2</b> (неуспех).
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    company_info: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.test-mode {
  border-top: 0.8px solid rgb(115, 115, 115);
  .api-h1 {
    padding-bottom: 20px !important;
  }
}

.bt-grey {
  border-top: 1px solid gainsboro;
  padding-top: 20px !important;
  margin-top: 20px !important;
}
</style>
