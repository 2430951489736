<template>
  <section class="sidebar">
    <div class="sidebar__logo" v-if="$props.company_info.logo">
      <span v-html="$props.company_info.logo"></span>
    </div>
    <div class="language">
      <img v-if="$i18n.locale == 'en'" src="../assets/US.svg" alt="">
      <img v-else src="../assets/RU.svg" alt="">
      <select class="lang-select" v-model="$i18n.locale">
        <option value="ru">
          
          Русский
          
          </option>
        <option value="en">English</option>
      </select>
    </div>
    <div class="sidebar__menus">
      <div class="sidebar__menu" v-for="(menu, i) in sidebarList" :key="i">
        <div v-if="!menu.subMenu" @click="scrollTo(menu.target, menu.subMenu, true)">
          {{ $t(menu.label) }}
          <span v-if="menu.subLabel">{{ $t(menu.subLabel) }}</span>
        </div>
        <div
          v-else
          class="sidebar__submenu"
          @click="scrollTo(menu.target, menu.subMenu)"
          :class="{ openSubMenu: menu.subMenuOpen }"
        >
          {{ $t(menu.label) }}
          <svg
            version="1.1"
            viewBox="0 0 24 24"
            x="0"
            xmlns="http://www.w3.org/2000/svg"
            y="0"
            aria-hidden="true"
            :class="{ 'active-svg': menu.subMenuOpen }"
          >
            <polygon
              fill="rgb(39, 50, 57)"
              points="17.3 8.3 12 13.6 6.7 8.3 5.3 9.7 12 16.4 18.7 9.7 "
            ></polygon>
          </svg>
        </div>
        <div
          class="sidebar__subMenus openSubMenu"
          v-if="menu.subMenu && menu.subMenuOpen && !menu.recurrent"
        >
          <div
            class="sidebar__subMenus-item"
            v-for="(subMenu, n) in api_list.slice(2).filter(api => !api.id.includes('recurrent-request'))"
            @click="scrollTo('#' + subMenu.id, false)"
            :key="n"
          >
            {{ subMenu.title }}
          </div>
        </div>
        <div
          class="sidebar__subMenus openSubMenu"
          v-if="menu.subMenu && menu.subMenuOpen && menu.recurrent"
        >
          <div
            class="sidebar__subMenus-item"
            v-for="(subMenu, n) in api_list.filter(api => api.id.includes('recurrent-request'))"
            @click="scrollTo('#' + subMenu.id, false)"
            :key="n"
          >
            {{ subMenu.title }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed } from "vue";
import i18n from "@/plugins/i18n";
export default {
  props: {
    company_info: {
      type: Object,
      default: () => ({}),
    },
    api_list: {
      type: Array,
      default: () => ([])
    }
  },
  setup(props) {
    const subMenuOpen = ref(false)

    const sidebarList = ref(
       [
        { label: "description", target: "#block1", subMenu: false },
        {
          label: "payment_execution_strc",
          target: "#structure",
          subMenu: false,
        },
        {
          label: "payment_crt",
          subLabel: "via_payment_page",
          target: "#block2",
          subMenu: false,
        },
        {
          label: "host_host",
          target: "#block3",
          subMenu: false,
        },
        {
          label: "Рекурентты",
          target: "#block3_1",
          subMenu: true,
          subMenuOpen: false,
          recurrent: true
        },
        {
          label: 'gen_inquiries',
          target: "#block4",
          subMenu: true,
          subMenuOpen: false,
          recurrent: false
        },
        {
          label: "payment_conf",
          target: "#block5",
          subMenu: false,
        },
        {
          label: "payment_conf_mob_elec",
          target: "#block6",
          subMenu: false,
        },
        {
          label: "token_payments",
          target: "#tokenPayout",
          subMenu: false,
        },
        {
          label: "Postman коллекция",
          target: "#postman",
          subMenu: false,
        },
        {
          label: "saving_cards",
          target: "#saveCard",
          subMenu: false,
        },
        { label: "payment_widget", target: "#payment-widget", subMenu: false },
        { label: "google_pay_text", target: "#google-pay", subMenu: false },
        { label: "test_env", target: "#test-mode", subMenu: false },
        { label: "MSDK", target: "#msdk", subMenu: false }
      ]);
    
    const scrollTo = (target, subMenu, closeSub) => {
      const element = document.querySelector(target);

      if (subMenu === true) {
        sidebarList.value.forEach((item) => {
          if (item.target === target) {
            item.subMenuOpen = !item.subMenuOpen;
          } else {
            item.subMenuOpen = false;
          }
        });
      }

      if (closeSub === true) {
        sidebarList.value.forEach((item) => {
          if (item.subMenuOpen) {
            item.subMenuOpen = false;
          }
        });
      }

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    return {
      subMenuOpen,
      sidebarList,
      scrollTo,
    };
  },
};
</script>

<style lang="scss">
.sidebar {
  padding-top: 20px;
  .language{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 8px;
    &>img{
      width: 20px;
    }
  }
  &__logo {
    padding: 0 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    // margin-bottom: 20px;
    height: 90px;
    svg,
    img {
      max-width: 150px;
      height: 87px;
    }
  }
  &__menus {
    border-top: 1px solid #dcdcdc;
  }
  &__menu {
    margin-top: 0px;
    width: 100%;
    .openSubMenu {
      &:hover {
        background: rgb(250, 250, 250) !important;
      }
    }
    & > div {
      cursor: pointer;
      font-family: Montserrat, sans-serif;
      // font-family: "Montserrat", monospace;
      font-size: 13.5px;
      font-weight: 500;
      width: 100%;
      padding: 10px 16px;
      transition: 0.2s;
      min-height: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &:hover {
        background: #e5e4e4;
      }
    }
    span {
      display: block;
      font-size: 11px;
      color: #858585;
      margin-top: 4px;
      font-family: "Montserrat", sans-serif;
    }
  }
  &__submenu {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    flex-direction: row !important;
    svg {
      width: 24px;
      height: 20px;
      transform: rotate(-90deg);
      transition: 0.25s;
    }
    .active-svg {
      transform: rotate(-180deg) !important;
    }
  }
  &__subMenus {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    &-item {
      min-height: 35px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      font-family: "Montserrat", sans-serif;
      &:hover {
        background: #e5e4e4;
      }
    }
  }
}

@media (max-width: 1099px) {
  .sidebar {
    display: none !important;
  }
}

@media (min-width: 1100px) and (max-width: 1200px) {
  .sidebar {
    &__menu {
      & > div {
        font-size: 12.5px;
      }
    }
    &__subMenus {
      &-item {
        font-size: 11px;
      }
    }
  }
}
</style>
