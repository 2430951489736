<template>
  <div v-highlight>
    <pre class="language-javascript">
    <code v-html="codeWithTranslations"></code>
    </pre>
  </div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
import i18n from "@/plugins/i18n";

export default {
  components: {
    VueCodeHighlight,
  },
  computed: {
    codeWithTranslations() {
      // Получаем текущий язык
      const currentLocale = this.$i18n.locale;
      
      // Определяем переводы в зависимости от языка
      const translations = {
        en: {
          shopPasswordComment: 'Shop password',
          encodeJsonComment: 'Encode the object into a JSON byte array',
          encodeBase64Comment: 'Encode JSON data in base64',
          generateSignatureComment: 'Generate signature',
          requestObjectComment: 'Request object'
        },
        ru: {
          shopPasswordComment: 'Пароль магазина',
          encodeJsonComment: 'Кодирование объекта в массив байтов JSON',
          encodeBase64Comment: 'Кодирование данных JSON в base64',
          generateSignatureComment: 'Генерация подписи',
          requestObjectComment: 'Объект запроса'
        }
      };

      // Выбираем нужный перевод
      const t = translations[currentLocale];

      return `
&lt;?php

    $secret_key = 'your_secret_key'; 
    $api_key = 'your_api_key';

    $dataObject = [
      'amount' => 100,
      'currency' => 'KZT',
      'order_id' => 'pay_' . time(),
      'description' => 'test_desc',
      'payment_type' => 'pay',
      'payment_method' => 'ecom',
      'email' => 'name@example.kz',
      'success_url' => 'https://example.com/',
      'failure_url' => 'https://example.com/',
      'callback_url' => 'https://example.com/',
      'merchant_term_url' => 'https://example.com/',
      'payment_lifetime' => 3600,
      'lang' => 'ru',
      'items' => [
        [
          'merchant_id' => 'your_merchant_id', // Replace with actual value
          'service_id' => 'your_service_id', // Replace with actual value
          'merchant_name' => 'merchant_name',
          'name' => 'name',
          'quantity' => 1,
          'amount_one_pcs' => 100,
          'amount_sum' => 100,
        ]
      ],
    ];

    $dataJson = json_encode($dataObject);
    $dataBase64 = base64_encode($dataJson);

    $sign = hash_hmac('sha512', $dataBase64, $secret_key);

    $request = [
      'data' => $dataBase64,
      'sign' => $sign,
    ];

    $token = base64_encode($api_key);

    $ch = curl_init();
    curl_setopt($ch, CURLOPT_URL, 'https://your-api-url/payment/create'); // Replace with actual URL
    curl_setopt($ch, CURLOPT_POST, 1);
    curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($request));
    curl_setopt($ch, CURLOPT_HTTPHEADER, [
        'Authorization: Bearer ' . $token,
        'Content-Type: application/json'
    ]);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

    $response = curl_exec($ch);
    curl_close($ch);

    $responseData = json_decode($response, true);
    print_r($responseData);

?&gt;
      `;
    }
  }
};
</script>
<style>
code.language-javascript, .token.constant{
  color: #7eb6f6 !important
}
</style>

