<template>
  <div class="api-section api-widget save-card postman">
    <h1 class="api-h1">Postman коллекция</h1>
    <p class="api-widget__description">
      Postman коллекция
    </p>
    <div class="files">
        <div class="file" v-for="(item, i) in postman" :key="i" @click="downloadFile(item.file)">
            <img src="../assets/json.png" alt="">
            <span>{{item.name}}</span>
        </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";


const postman = ref([
    {
        name: "H2H",
        file: "H2H.postman_collection.json"
    },
    {
        name: "Возврат средств",
        file: "Возврат средств.postman_collection"
    },
    {
        name: "Выплата на карту",
        file: "Выплата на карту.postman_collection"
    },
    {
        name: "Выплата на карту UZS",
        file: "Выплата на карту UZS.postman_collection"
    },
    {
        name: "Запрос статуса",
        file: "Запрос статуса.postman_collection"
    },
    {
        name: "Мобильная коммерция H2H",
        file: "Мобильная коммерция H2H.postman_collection"
    },
    {
        name: "Мобильная коммерция",
        file: "Мобильная коммерция.postman_collection"
    },
    {
        name: "Платежная страница",
        file: "Платежная страница.postman_collection"
    },
    {
        name: "Подтверждение платежа",
        file: "Подтверждение платежа.postman_collection"
    },
    {
        name: "Получение квитанции",
        file: "Получение квитанции.postman_collection"
    },
    {
        name: "Привязочный платеж H2H",
        file: "Привязочный платеж H2H.postman_collection"
    },
    {
        name: "Привязочный платеж через платежную страницу",
        file: "Привязочный платеж через платежную страницу.postman_collection"
    },
    {
        name: "Проверка выплатного баланса",
        file: "Проверка выплатного баланса.postman_collection"
    },
    {
        name: "Списание заблокированных средств",
        file: "Списание заблокированных средств.postman_collection"
    },
    {
        name: "Токенизированная выплата",
        file: "Токенизированная выплата.postman_collection"
    },
    {
        name: "Последующий рекуррентный платеж",
        file: "Последующий рекуррентный платеж.postman_collection"
    }
])

const downloadFile = (filename) => {
    const fileUrl = `/${filename}`;

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;  

    link.click();
}
</script>

<style lang="scss">
.save-card {
  border-top: 0.8px solid rgb(115, 115, 115);
  border-bottom: 0.8px solid rgb(115, 115, 115);
  .api-h1 {
    max-width: 100%;
  }
  &-suptitle {
    border-top: 1px solid rgb(115, 115, 115);
    border-bottom: 1px solid rgb(115, 115, 115);
    display: block;
    padding: 24px 0;
    width: max-content;
  }
}

.api-widget {
  width: 60%;
  padding-bottom: 40px;
  .api-h1 {
    margin-bottom: 0;
  }
  &__description {
    margin-top: 0;
    padding: 0 40px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    line-height: 1.4em;
    font-size: 15.5px;
    color: rgb(51, 51, 51);
  }
  .api-title {
    margin-top: 50px;
  }
  &__code {
    margin: 20px 40px;
    & > pre {
      padding-bottom: 0;
    }
  }
  &__screen {
    width: 100%;
    padding: 20px 40px 0;
    object-fit: contain;
    object-position: center center;
  }
  &__demo {
    padding: 40px 40px 0px;
    display: flex;
    align-items: center;
    span {
      margin-right: 15px;
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: rgb(51, 51, 51);
    }
    button {
      border: 1.5px solid #19226d;
      background: transparent;
      color: #19226d;
      font-size: 14px;
      font-weight: 500;
      border-radius: 4px;
      height: 34px;
      padding: 0 20px;
      cursor: pointer;
      transition: 0.25s;
      &:hover {
        background: #19226d;
        color: white;
      }
    }
  }
}

.onevision-widget {
  z-index: 1111111111;
}

.token-code {
  padding: 0 40px;
}

.postman{
    .files{
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        .file{
            display: flex;
            align-items: center;
            gap: 12px;
            border: 1px solid #eaecf0;
            border-radius: 8px;
            padding: 6px 8px;
            cursor: pointer;
            img{
                width: 48px;
                height: 48px;
            }
            span{
                font-size: 14px;
                font-weight: 500;
                color: #344054;
                line-height: normal;
            }
        }
    }
}

@media (max-width: 939px) {
  .api-widget {
    width: 100%;
  }
}
</style>
